.standings-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.header {
  margin: auto;
}

.table-striped {
  font-size: 1.3rem;
}

.table-striped > thead > tr > th {
  background-color: rgba(245, 245, 245, 0.8);
  border: none;
  height: 3rem !important;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  /* background-color: #294566; */
  background-color: rgba(245, 245, 245, 0.8);
  border-color: rgba(144, 183, 196, 0.8);
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: rgba(199, 233, 245, 0.8);
  text-align: center;
  border-color: rgba(144, 183, 196, 0.8);
}
