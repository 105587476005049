.info {
  color: white;
  font-weight: 600;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 2rem;
}

.game-info {
  margin-bottom: 2rem;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  height: 100%;
  width: 100%;
  margin: 0;
  /* border-style: double; */
}

.main-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5;
  /* background-color: rgb(186, 34, 17); */
}

.arrow::before {
  border-bottom-color: transparent !important;
}
