body {
  background-color: #2d2d2d;
}

.clock {
  font: small-caps 43px/150% 'Segoe UI', Frutiger, 'Frutiger Linotype',
    'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  color: #fff;
  margin: 0 3rem;
}
